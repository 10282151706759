// Eventos de Scroll
$(window).on('scroll', function () {
    if ($(window).scrollTop()) {
        Hide_nav();
    }
    if ($(window).scrollTop() < 299) {
        $('nav#nav-desktop').addClass('position-absolute');
        $('nav#nav-desktop').removeClass('fixed-top');
        $('nav#nav-desktop').removeClass('bg-marrom');
        $('nav#nav-desktop').addClass('bg-transparent');
        $('nav#nav-desktop').removeClass('py-0');
        $('nav#nav-desktop').removeClass('nav-desktop-show');
        $('nav#nav-desktop').removeClass('shadow-lg');
        $('img#logo-desktop').attr('width', '220');

        $('nav#nav-mobile').addClass('position-absolute');
        $('nav#nav-mobile').removeClass('fixed-top');
        $('nav#nav-mobile').removeClass('bg-marrom');
        $('nav#nav-mobile').addClass('bg-transparent');
        $('nav#nav-mobile').removeClass('py-0');
        $('nav#nav-mobile').addClass('py-2');
        $('nav#nav-mobile').removeClass('nav-desktop-show');
        $('nav#nav-mobile').removeClass('shadow-lg');
        $('img#logo-mobile').attr('width', '155');
    }
    if ($(window).scrollTop() > 300) {
        $('nav#nav-desktop').removeClass('position-absolute');
        $('nav#nav-desktop').addClass('fixed-top');
        $('nav#nav-desktop').addClass('bg-marrom');
        $('nav#nav-desktop').removeClass('bg-transparent');
        $('nav#nav-desktop').addClass('py-0');
        $('nav#nav-desktop').addClass('nav-desktop-show');
        $('nav#nav-desktop').addClass('shadow-lg');
        $('img#logo-desktop').attr('width', '200');

        $('nav#nav-mobile').removeClass('position-absolute');
        $('nav#nav-mobile').addClass('fixed-top');
        $('nav#nav-mobile').addClass('bg-marrom');
        $('nav#nav-mobile').removeClass('bg-transparent');
        $('nav#nav-mobile').addClass('py-0');
        $('nav#nav-mobile').removeClass('py-2');
        $('nav#nav-mobile').addClass('nav-desktop-show');
        $('nav#nav-mobile').addClass('shadow-lg');
        $('img#logo-mobile').attr('width', '155');
    }

    if ($(window).scrollTop() > 0 && $(window).scrollTop() < 450) {
        $('div#borda-marcacao-1').addClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-2').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-3').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-4').removeClass('borda-amarela-nav-scroll');
    }
    if ($(window).scrollTop() > 450 && $(window).scrollTop() < 900) {
        $('div#borda-marcacao-1').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-2').addClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-3').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-4').removeClass('borda-amarela-nav-scroll');
    }
    if ($(window).scrollTop() > 900 && $(window).scrollTop() < 1800) {
        $('div#borda-marcacao-1').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-2').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-3').addClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-4').removeClass('borda-amarela-nav-scroll');
    }
    if ($(window).scrollTop() > 1800) {
        $('div#borda-marcacao-1').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-2').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-3').removeClass('borda-amarela-nav-scroll');
        $('div#borda-marcacao-4').addClass('borda-amarela-nav-scroll');
    }
});
// Eventos de Click
function Show_Nav () {
    $('div#nav-event').addClass('nav-on');
    $('div#nav-event').removeClass('nav-off');
    $('div#bg-branco').fadeIn(200);
    $('div#bg-branco').removeClass('d-none');
}
function Hide_nav() {
    $('div#nav-event').addClass('nav-off');
    $('div#nav-event').removeClass('nav-on');
    $('div#bg-branco').fadeOut(200);
}

$('button#show-nav').click(function (e) { 
    e.preventDefault();
    Show_Nav();
});

$('div#bg-branco, button#hide-nav').click(function (e) { 
    e.preventDefault();
    Hide_nav();
});

$('nav#nav-mobile a.nav-link, nav#nav-desktop a.nav-link, footer .text-cinza-link-footer, #home a.nav-link').click(function (e) { 
    e.preventDefault();
     let id = $(this).attr('href'),
     targetOffset = $(id).offset().top;
     $('html, body').animate({
        scrollTop: targetOffset - 74
     }, 400);
});


// Eventos de Mouseenter e mouseleave
// Mouse enter e mouse leave dos links da navbar-desktop
$('#li-nav-desktop-1').mouseenter(function () { 
    $('#borda-marcacao-1').addClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-1').removeClass('borda-amarela-nav');
});
$('#li-nav-desktop-1').mouseleave(function () { 
    $('#borda-marcacao-1').removeClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-1').addClass('borda-amarela-nav');
});

$('#li-nav-desktop-2').mouseenter(function () { 
    $('#borda-marcacao-2').addClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-2').removeClass('borda-amarela-nav');
});
$('#li-nav-desktop-2').mouseleave(function () { 
    $('#borda-marcacao-2').removeClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-2').addClass('borda-amarela-nav');
});

$('#li-nav-desktop-3').mouseenter(function () { 
    $('#borda-marcacao-3').addClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-3').removeClass('borda-amarela-nav');
});
$('#li-nav-desktop-3').mouseleave(function () { 
    $('#borda-marcacao-3').removeClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-3').addClass('borda-amarela-nav');
});

$('#li-nav-desktop-4').mouseenter(function () { 
    $('#borda-marcacao-4').addClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-4').removeClass('borda-amarela-nav');
});
$('#li-nav-desktop-4').mouseleave(function () { 
    $('#borda-marcacao-4').removeClass('borda-amarela-nav-ativa');
    $('#borda-marcacao-4').addClass('borda-amarela-nav');
});

// Mouse enter e mouse leave dos links do rodapé
if ($(window).width() > 992) {
    $('#link-footer-1').mouseenter(function () { 
        $('#borda-marcacao-footer-1').addClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-1').removeClass('borda-amarela-nav');
    });
    $('#link-footer-1').mouseleave(function () { 
        $('#borda-marcacao-footer-1').removeClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-1').addClass('borda-amarela-nav');
    });
    $('#link-footer-2').mouseenter(function () { 
        $('#borda-marcacao-footer-2').addClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-2').removeClass('borda-amarela-nav');
    });
    $('#link-footer-2').mouseleave(function () { 
        $('#borda-marcacao-footer-2').removeClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-2').addClass('borda-amarela-nav');
    });
    $('#link-footer-3').mouseenter(function () { 
        $('#borda-marcacao-footer-3').addClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-3').removeClass('borda-amarela-nav');
    });
    $('#link-footer-3').mouseleave(function () { 
        $('#borda-marcacao-footer-3').removeClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-3').addClass('borda-amarela-nav');
    });
    $('#link-footer-4').mouseenter(function () { 
        $('#borda-marcacao-footer-4').addClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-4').removeClass('borda-amarela-nav');
    });
    $('#link-footer-4').mouseleave(function () { 
        $('#borda-marcacao-footer-4').removeClass('borda-amarela-nav-ativa');
        $('#borda-marcacao-footer-4').addClass('borda-amarela-nav');
    });
}

// Mouseleave e mouseenter das divs da seção 'Nossos serviços'
$('#div-nossos-servicos-1').mouseenter(function () { 
    $('#div-nossos-servicos-1').addClass('shadow-lg');
    $('#div-nossos-servicos-1').removeClass('shadow-sm');
});
$('#div-nossos-servicos-1').mouseleave(function () { 
    $('#div-nossos-servicos-1').removeClass('shadow-lg');
    $('#div-nossos-servicos-1').addClass('shadow-sm');
});
$('#div-nossos-servicos-2').mouseenter(function () { 
    $('#div-nossos-servicos-2').addClass('shadow-lg');
    $('#div-nossos-servicos-2').removeClass('shadow-sm');
});
$('#div-nossos-servicos-2').mouseleave(function () { 
    $('#div-nossos-servicos-2').removeClass('shadow-lg');
    $('#div-nossos-servicos-2').addClass('shadow-sm');
});
$('#div-nossos-servicos-3').mouseenter(function () { 
    $('#div-nossos-servicos-3').addClass('shadow-lg');
    $('#div-nossos-servicos-3').removeClass('shadow-sm');
});
$('#div-nossos-servicos-3').mouseleave(function () { 
    $('#div-nossos-servicos-3').removeClass('shadow-lg');
    $('#div-nossos-servicos-3').addClass('shadow-sm');
});
$('#div-nossos-servicos-4').mouseenter(function () { 
    $('#div-nossos-servicos-4').addClass('shadow-lg');
    $('#div-nossos-servicos-4').removeClass('shadow-sm');
});
$('#div-nossos-servicos-4').mouseleave(function () { 
    $('#div-nossos-servicos-4').removeClass('shadow-lg');
    $('#div-nossos-servicos-4').addClass('shadow-sm');
});
$('#div-nossos-servicos-5').mouseenter(function () { 
    $('#div-nossos-servicos-5').addClass('shadow-lg');
    $('#div-nossos-servicos-5').removeClass('shadow-sm');
});
$('#div-nossos-servicos-5').mouseleave(function () { 
    $('#div-nossos-servicos-5').removeClass('shadow-lg');
    $('#div-nossos-servicos-5').addClass('shadow-sm');
});
$('#div-nossos-servicos-6').mouseenter(function () { 
    $('#div-nossos-servicos-6').addClass('shadow-lg');
    $('#div-nossos-servicos-6').removeClass('shadow-sm');
});
$('#div-nossos-servicos-6').mouseleave(function () { 
    $('#div-nossos-servicos-6').removeClass('shadow-lg');
    $('#div-nossos-servicos-6').addClass('shadow-sm');
});